import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const GYIK = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="GYIK"/>

      <article>
        <header>
          <h1>GYIK</h1>
        </header>
        <section>
          Még semmi...
        </section>
      </article>

    </Layout>
  )
}

export default GYIK
